
.login {
    background: #000 url(../../img/sauron.gif) center center no-repeat;
    background-size: cover;
}

.login .video-overlay {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: darken(@brand-primary, 4%);
    opacity: .7;
}

.login-box {
    .make-sm-column(4);
    padding: 0;
    position: absolute;
    top:50%;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login {
    text-align: center;

    .panel-heading {
        background: darken(@brand-primary, 4%);
        color: #fff;
        text-transform: uppercase;
    }
}