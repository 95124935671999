.nav-center {
    text-align: center;
    ul.nav {
        display: inline-block;
        li {
            display: inline;

            a {
                float: left
            }
        }
    }
}