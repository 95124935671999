.issues-nav {
    background: @white;
    border-left: 1px solid @gray-light;
    border-right: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    margin-bottom: @padding-base-horizontal;
    .box-shadow(0 1px 1px rgba(0,0,0,.05));
}
.issueList-panel {
    border: none;
    box-shadow: none;
    background: transparent;
}

.issueList-panel .panel-heading {
    border-bottom: 1px solid @gray-light;
    border-left: 1px solid @gray-light;
    border-right: 1px solid @gray-light;
}

.issueList {
    background: transparent;
}
.issueList .issueItem {
    border-bottom: 1px solid @gray-light;
    border-left: 1px solid @gray-light;
    border-right: 1px solid @gray-light;
    .panel-body;
    position: relative;
    background: @white;
    transition: all 150ms cubic-bezier(0.95, 0.05, 0.795, 0.035);
    .box-shadow(0 1px 1px rgba(0,0,0,.05));

}
.issueList .issueItem:hover {
    cursor: pointer;
}
.issueList .issueItem.is-open {
    margin: @padding-base-horizontal -@padding-base-horizontal;
    border: 1px solid @gray-light;
    .box-shadow(0 1px 1px rgba(0,0,0,.05));
}

.issueList .issueItem .issueItem-title,
.issueList .issueItem .issueItem-message {
    margin: 0;
}

.issueItem .issueItem-title .issueItem-error {
    font-size: @font-size-h3;
    margin-right: @padding-base-horizontal;
}
.issueItem .issueItem-title .issueItem-method,
.issueItem .issueItem-title .issueItem-path {
    font-size: 14px;
    color: @gray;
}
.issueItem .issueItem-message {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: @md-grey;
}

.issueItem .issueItem-meta {
    color: @md-grey;
    list-style: none;
    margin: 0;
    padding: 0;
}

.issueItem .issueItem-meta li {
    display: inline-block;
}

.issueItem .issueItem-meta li:after {
    color: lighten(@gray, 20%);
    content: "|";
    margin: 0 5px;
}

.issueItem .issueItem-meta li:last-child:after {
    content: initial;
}

.issueDetail-close {
    .btn;
    .text-center;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: none;
    border-radius: 0;
    background: @gray-light;
    vertical-align: middle;
}