form {
    .container;
}

.form-actions {
    margin-bottom: 0;
    .row;
}

.control-label--required:after {
    content: ' *';
    color: @brand-danger;
}