*, :active, :hover {
    outline: 0 !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}
*, button, input, i, a {
    -webkit-font-smoothing: antialiased;
}
a:hover, a:focus {
    text-decoration: none;
}
html, body {
    height: 100%;
}
body {
    background: @gray-lighter;
}

a[ng-click]{
    cursor: pointer;
}

th, strong, label, dt {
    font-weight: 500;
}
.fixed {
    position: fixed;
    margin: 0;
    z-index: 1000;
}
.bottomleft, .bottomright {
    bottom: 20px;
}
.bottomleft {
    left: 20px;
    @media (min-width: @screen-lg) {
        left: @navbar-width + 20;
    }
}

.bottomright {
    right: 20px;
}

.icon {
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
}

#main.affixed {
    @media (max-width: @screen-lg) {
        margin-top: 64px;
    }
}